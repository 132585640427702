import React from "react";

interface PlusIconModalProps {
  className?: string;
  fill?: string;
  height?: number;
  stroke?: string;
  width?: number;
}

const PlusIconModal = ({
  className,
  fill = "none",
  height = 34,
  width = 34,
  stroke = "rgb(101, 101, 101)",
  ...rest
}: PlusIconModalProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 34 34"
    className={className}
    {...rest}
  >
    <g id="Group_559" data-name="Group 559" transform="translate(-13304 261)">
      <line
        id="Line_89"
        data-name="Line 89"
        y2="30"
        transform="translate(13321 -259)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="4"
      />
      <line
        id="Line_90"
        data-name="Line 90"
        y2="30"
        transform="translate(13336 -244) rotate(90)"
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth="4"
      />
    </g>
  </svg>
);

export default PlusIconModal;
