import React, { ReactNode } from "react";
import { useLanguage } from "../../../../i18n/LanguageContext";
import PlusIconModal from "../../../../icons/PlusIconModal";
import {
  findContent,
  SanityLandingPageString
} from "../../../../model/landing-page";
import { SanityCallToAction, SanityImage } from "../../../../model/common";
import { createLinkTo } from "../../../../utils/links";
import { imageUrlBuilder } from "../../../../utils/images";
import Link from "../../../Link/Link";
import MaybeLink from "../../../MaybeLink/MaybeLink";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps } from "../../../PageModal/PageModal";
import WithSpacing from "../../../Spacing/Spacing";
import StepperStyledButton from "../../../Stepper/StepperButton/StepperStyledButton";
import Text from "../../../Text/Text";
import styles from "./YouWillNeedModal.module.scss";
import { shouldSkipMissingImageAsset } from "../../../Image/Image";

function isItem(item: ItemProps | ReactNode): item is ItemProps {
  return (
    (item as ItemProps).image !== undefined &&
    (item as ItemProps).title !== undefined &&
    (item as ItemProps).subtitle !== undefined
  );
}

const YouWillNeedModal = ({ content, onClose }: PageModalBodyProps) => {
  const [secondaryCTA] = findContent<SanityCallToAction>(
    "secondaryCTA",
    content
  );
  const language = useLanguage();
  const [closeButtonText] = findContent<SanityLandingPageString>(
    "closeButtonText",
    content
  );
  const [text] = findContent<SanityLandingPageString>("text", content);
  const [link] = findContent<SanityCallToAction>("link", content);
  const [
    itemOneImage,
    itemTwoImage,
    itemThreeImage,
    itemFourImage
  ] = findContent<SanityImage>("itemImages", content);
  const [
    itemOneTitle,
    itemOneSubtitle,
    itemTwoTitle,
    itemTwoSubtitle,
    itemThreeTitle,
    itemThreeSubtitle
  ] = findContent<SanityLandingPageString>("itemText", content);

  const items: (ItemProps | ReactNode)[] = [
    {
      image: itemOneImage,
      title: itemOneTitle.value,
      subtitle: itemOneSubtitle.value
    },
    <PlusIconModal className={styles.itemIcon} aria-hidden />,
    {
      image: itemTwoImage,
      title: itemTwoTitle.value,
      subtitle: itemTwoSubtitle.value
    },
    <PlusIconModal className={styles.itemIcon} aria-hidden />,
    {
      image: itemThreeImage,
      additionalImage: itemFourImage,
      title: itemThreeTitle.value,
      subtitle: itemThreeSubtitle.value
    }
  ];
  return (
    <div className={styles.root}>
      <ModalBody>
        <WithSpacing>
          <div>
            {text?.value && <Text variant="default">{text.value}</Text>}
            <ul className={styles.items}>
              {items.map((item, index) => {
                if (isItem(item)) {
                  return <Item key={item.image._key} {...item} />;
                }
                return <React.Fragment key={index}>{item}</React.Fragment>;
              })}
            </ul>
          </div>
        </WithSpacing>
      </ModalBody>

      <ModalFooter className={styles.footer}>
        <div className={styles.footerLinks}>
          <Text variant="default">
            <Link to={createLinkTo(link, language)} onClick={onClose}>
              {link.title}
            </Link>
          </Text>
          <Text variant="default">
            <Link to={createLinkTo(secondaryCTA, language)} onClick={onClose}>
              {secondaryCTA.title}
            </Link>
          </Text>
        </div>
        <StepperStyledButton
          onClick={onClose}
          bold
          text={closeButtonText.value}
        />
      </ModalFooter>
    </div>
  );
};

interface ItemProps {
  image: SanityImage;
  additionalImage?: SanityImage | undefined;
  title: string;
  subtitle: string;
}

const Item = ({ image, title, subtitle, additionalImage }: ItemProps) => (
  <li className={styles.item}>
    <MaybeLink to={image.externalLink}>
      {!shouldSkipMissingImageAsset(image) && (
        <img
          alt=""
          width={150}
          height={150 / (image.asset.metadata.dimensions.aspectRatio ?? 1)}
          src={
            imageUrlBuilder
              .image(image.asset)
              .width(300)
              .url()!
          }
        />
      )}
    </MaybeLink>
    {additionalImage && (
      <MaybeLink to={additionalImage.externalLink}>
        {!shouldSkipMissingImageAsset(image) && (
          <img
            alt=""
            width={150}
            height={
              150 / (additionalImage.asset.metadata.dimensions.aspectRatio ?? 1)
            }
            src={
              imageUrlBuilder
                .image(additionalImage.asset)
                .width(300)
                .url()!
            }
          />
        )}
      </MaybeLink>
    )}
    <div className={styles.itemText}>
      <Text variant="defaultBold">{title}</Text>
      <Text variant="default">{subtitle}</Text>
    </div>
  </li>
);

export default YouWillNeedModal;
