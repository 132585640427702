import React, { ReactNode } from "react";
import { useLanguage } from "../../../../i18n/LanguageContext";
import PlusIconModal from "../../../../icons/PlusIconModal";
import {
  findContent,
  SanityLandingPageString
} from "../../../../model/landing-page";
import { SanityCallToAction, SanityImage } from "../../../../model/common";
import { createLinkTo } from "../../../../utils/links";
import { imageUrlBuilder } from "../../../../utils/images";
import Link from "../../../Link/Link";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps } from "../../../PageModal/PageModal";
import WithSpacing from "../../../Spacing/Spacing";
import StepperStyledButton from "../../../Stepper/StepperButton/StepperStyledButton";
import Text from "../../../Text/Text";
import styles from "./PowerUpAndPlayModal.module.scss";
import { shouldSkipMissingImageAsset } from "../../../Image/Image";

function isItem(item: ItemProps | ReactNode): item is ItemProps {
  return (
    (item as ItemProps).image !== undefined &&
    (item as ItemProps).title !== undefined &&
    (item as ItemProps).subtitle !== undefined
  );
}

const PowerUpAndPlayModal = ({ content, onClose }: PageModalBodyProps) => {
  const [text] = findContent<SanityLandingPageString>("text", content);
  const [secondaryCTA] = findContent<SanityCallToAction>(
    "secondaryCTA",
    content
  );
  const [closeButtonText] = findContent<SanityLandingPageString>(
    "closeButtonText",
    content
  );
  const [itemOneImage, itemTwoImage, itemThreeImage] = findContent<SanityImage>(
    "itemImages",
    content
  );
  const [
    itemOneTitle,
    itemTwoTitle,
    itemThreeTitle,
    itemSubtitle
  ] = findContent<SanityLandingPageString>("itemText", content);

  const items: (ItemProps | ReactNode)[] = [
    {
      image: itemOneImage,
      title: itemOneTitle.value,
      subtitle: itemSubtitle.value
    },
    <PlusIconModal className={styles.itemIcon} aria-hidden />,
    {
      image: itemTwoImage,
      title: itemTwoTitle.value,
      subtitle: itemSubtitle.value
    },
    <PlusIconModal className={styles.itemIcon} aria-hidden />,
    {
      image: itemThreeImage,
      title: itemThreeTitle.value,
      subtitle: itemSubtitle.value
    }
  ];
  const language = useLanguage();
  return (
    <div className={styles.root}>
      <ModalBody>
        <WithSpacing>
          <div>
            <Text variant="default">{text.value}</Text>
            <ul className={styles.items}>
              {items.map((item, index) => {
                if (isItem(item)) {
                  return <Item key={item.image._key} {...item} />;
                }
                return <React.Fragment key={index}>{item}</React.Fragment>;
              })}
            </ul>
          </div>
        </WithSpacing>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Text variant="default">
          <Link to={createLinkTo(secondaryCTA, language)}>
            {secondaryCTA.title}
          </Link>
        </Text>
        <StepperStyledButton
          onClick={onClose}
          bold
          text={closeButtonText.value}
        />
      </ModalFooter>
    </div>
  );
};

interface ItemProps {
  image: SanityImage;
  title: string;
  subtitle: string;
}

const Item = ({ image, title, subtitle }: ItemProps) => {
  return (
    <li className={styles.item}>
      {!shouldSkipMissingImageAsset(image) && (
        <img
          alt=""
          width={image.asset.metadata.dimensions.width}
          height={image.asset.metadata.dimensions.height}
          src={
            imageUrlBuilder
              .image(image.asset)
              .width(image.asset.metadata.dimensions.width * 2)
              .url()!
          }
        />
      )}
      <div className={styles.itemText}>
        <Text variant="defaultBold">{title}</Text>
        <Text variant="default">{subtitle}</Text>
      </div>
    </li>
  );
};

export default PowerUpAndPlayModal;
