import React, { useCallback, useState } from "react";
import GettingStartedRadio from "../../../../components/GettingStartedRadio/GettingStartedRadio";
import {
  findContent,
  SanityLandingPageString
} from "../../../../model/landing-page";
import { SanityCallToAction, SanityImage } from "../../../../model/common";
import BlockContent from "../../../BlockContent/BlockContent";
import { Col, Row } from "../../../Grid";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps, triggerModal } from "../../../PageModal/PageModal";
import WithSpacing from "../../../Spacing/Spacing";
import StepperStyledButton from "../../../Stepper/StepperButton/StepperStyledButton";
import { SanityStyledContent } from "../../../StyledContent/StyledContent";
import styles from "./SelectMicrobitModal.module.scss";

const SelectMicrobitModal = ({ content, onClose }: PageModalBodyProps) => {
  const [choice, setChoice] = useState<"V1" | "V2">();
  const [styledContent] = findContent<SanityStyledContent>("content", content);
  const [imageV2, imageV1] = findContent<SanityImage>("itemImages", content);
  const [titleV2, subtitleV2, titleV1, subtitleV1] = findContent<
    SanityLandingPageString
  >("itemText", content);
  const [ctaV2, ctaV1] = findContent<SanityCallToAction>("ctaItems", content);

  const handleChange = useCallback((value: string) => {
    if (value === "V1" || value === "V2") {
      setChoice(value);
    }
  }, []);

  const handleClick = useCallback(() => {
    if (choice === "V2" && ctaV2.targetModal) {
      triggerModal(ctaV2.targetModal);
    } else if (choice === "V1" && ctaV1.targetModal) {
      triggerModal(ctaV1.targetModal);
    }
    onClose();
  }, [choice, ctaV1.targetModal, ctaV2.targetModal, onClose]);

  const items = [
    {
      image: imageV2,
      title: titleV2.value,
      subtitle: subtitleV2.value,
      value: "V2",
      onChange: handleChange,
      selected: choice === "V2"
    },
    {
      image: imageV1,
      title: titleV1.value,
      subtitle: subtitleV1.value,
      value: "V1",
      onChange: handleChange,
      selected: choice === "V1"
    }
  ];

  return (
    <div className={styles.root}>
      <ModalBody>
        <WithSpacing>
          <div>
            <BlockContent content={styledContent.content} />
            <Row className={styles.items}>
              {items.map((item, index) => (
                <Col key={index} className={styles.item} xs={12} sm={6}>
                  <GettingStartedRadio
                    id="microbit"
                    value={item.value}
                    title={item.title}
                    subtitle={item.subtitle}
                    image={item.image}
                    onChange={item.onChange}
                    selected={item.selected}
                    tabbable={!choice ? index === 0 : item.selected}
                  />
                </Col>
              ))}
            </Row>
          </div>
        </WithSpacing>
      </ModalBody>
      <ModalFooter>
        <StepperStyledButton
          onClick={handleClick}
          text={choice === "V2" ? ctaV2.title : ctaV1.title}
          bold
          disabled={!choice}
        />
      </ModalFooter>
    </div>
  );
};

export default SelectMicrobitModal;
