import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./ModalFooter.module.scss";

interface ModalFooterProps {
  children: ReactNode;
  className?: string;
}

const ModalFooter = ({ className, children }: ModalFooterProps) => (
  <div className={classNames(styles.root, className)}>{children}</div>
);

export default ModalFooter;
