import { navigate } from "gatsby";
import React, { useCallback } from "react";
import { useLanguage } from "../../../../i18n/LanguageContext";
import { findContent } from "../../../../model/landing-page";
import { SanityCallToAction } from "../../../../model/common";
import { createLinkTo } from "../../../../utils/links";
import BlockContent from "../../../BlockContent/BlockContent";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps } from "../../../PageModal/PageModal";
import StepperStyledButton from "../../../Stepper/StepperButton/StepperStyledButton";
import { SanityStyledContent } from "../../../StyledContent/StyledContent";

const NoWelcomeProgramModal = ({ content }: PageModalBodyProps) => {
  const [styledContent] = findContent<SanityStyledContent>("content", content);
  const [cta] = findContent<SanityCallToAction>("cta", content);
  const language = useLanguage();
  const to = createLinkTo(cta, language);
  const handleClick = useCallback(() => {
    navigate(to);
  }, [to]);
  return (
    <>
      <ModalBody>
        <BlockContent content={styledContent.content} />
      </ModalBody>
      <ModalFooter>
        <StepperStyledButton onClick={handleClick} text={cta.title} bold />
      </ModalFooter>
    </>
  );
};

export default NoWelcomeProgramModal;
