import { navigate } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import GettingStartedRadio from "../../../../components/GettingStartedRadio/GettingStartedRadio";
import { useLanguage } from "../../../../i18n/LanguageContext";
import {
  findContent,
  SanityLandingPageString
} from "../../../../model/landing-page";
import { SanityCallToAction, SanityImage } from "../../../../model/common";
import { createLinkTo } from "../../../../utils/links";
import { useGatsbyLocation } from "../../../../utils/useGatsbyLocation";
import BlockContent from "../../../BlockContent/BlockContent";
import PlainButton from "../../../Button/PlainButton/PlainButton";
import { Col, Row } from "../../../Grid";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps, triggerModal } from "../../../PageModal/PageModal";
import WithSpacing from "../../../Spacing/Spacing";
import StepperStyledButton from "../../../Stepper/StepperButton/StepperStyledButton";
import { SanityStyledContent } from "../../../StyledContent/StyledContent";
import Text from "../../../Text/Text";
import styles from "./SelectProgramModal.module.scss";

interface SelectProgramModalProps extends PageModalBodyProps {
  inputKey: string;
}

const SelectProgramModal = ({
  content,
  onClose,
  inputKey
}: SelectProgramModalProps) => {
  const [program, setProgram] = useState<
    "oldWelcomeProgram" | "newWelcomeProgram" | "other"
  >();
  const [currentProgram, setCurrentProgram] = useState<"new" | "old">();
  const [styledContent] = findContent<SanityStyledContent>("content", content);
  const [
    oldWelcomeProgramImage,
    somethingElseImage,
    newWelcomeProgramImage
  ] = findContent<SanityImage>("images", content);
  const [
    oldWelcomeProgramTitle,
    oldWelcomeProgramSubtitle,
    somethingElseTitle,
    somethingElseSubtitle,
    newWelcomeProgramTitle,
    newWelcomeProgramSubtitle
  ] = findContent<SanityLandingPageString>("text", content);
  const [
    oldWelcomeProgramCta,
    somethingElseCta,
    newWelcomeProgramCta
  ] = findContent<SanityCallToAction>("ctas", content);
  const language = useLanguage();
  const cta =
    program === "oldWelcomeProgram"
      ? oldWelcomeProgramCta
      : newWelcomeProgramCta;
  const to = program && createLinkTo(cta, language);
  const [videoLink] = findContent<SanityCallToAction>("secondaryCTA", content);

  const { search } = useGatsbyLocation();
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const queryParamValue = queryParams.get("welcomeProgram");
    setCurrentProgram(queryParamValue === "old" ? "old" : "new");
  }, [search]);

  const handleClick = useCallback(() => {
    if (
      to &&
      (program === "newWelcomeProgram" || program === "oldWelcomeProgram")
    ) {
      navigate(to, {
        replace: true,
        state: {
          scrollToElementId: `#welcome-to-your-microbit-${
            program === "newWelcomeProgram" ? "new" : "old"
          }`
        }
      });
    } else if (program === "other" && somethingElseCta.targetModal) {
      triggerModal(somethingElseCta.targetModal);
    }
    onClose();
  }, [onClose, program, somethingElseCta.targetModal, to]);

  const handleOnChange = useCallback((value: string) => {
    if (
      value === "newWelcomeProgram" ||
      value === "oldWelcomeProgram" ||
      value === "other"
    ) {
      setProgram(value);
    }
  }, []);

  const oldWelcomeProgramItem = {
    title: oldWelcomeProgramTitle.value,
    subtitle: oldWelcomeProgramSubtitle.value,
    value: "oldWelcomeProgram",
    image: oldWelcomeProgramImage,
    onChange: handleOnChange,
    selected: program === "oldWelcomeProgram"
  };

  const newWelcomeProgramItem = {
    title: newWelcomeProgramTitle.value,
    subtitle: newWelcomeProgramSubtitle.value,
    value: "newWelcomeProgram",
    image: newWelcomeProgramImage,
    onChange: handleOnChange,
    selected: program === "newWelcomeProgram"
  };

  const items = [
    currentProgram === "new" ? oldWelcomeProgramItem : newWelcomeProgramItem,
    {
      title: somethingElseTitle.value,
      subtitle: somethingElseSubtitle.value,
      value: "other",
      image: somethingElseImage,
      onChange: handleOnChange,
      selected: program === "other"
    }
  ];

  const handleOpenVideo = useCallback(() => {
    if (videoLink.targetModal) {
      triggerModal(videoLink.targetModal);
    }
  }, [videoLink]);

  return (
    <div className={styles.root}>
      <ModalBody>
        <WithSpacing>
          <div>
            <BlockContent content={styledContent.content} />
            <Row className={styles.items}>
              {items.map((item, index) => (
                <Col key={index} className={styles.item} xs={12} sm={6}>
                  <GettingStartedRadio
                    id={`program-${inputKey}`}
                    value={item.value}
                    title={item.title}
                    subtitle={item.subtitle}
                    image={item.image}
                    onChange={item.onChange}
                    selected={item.selected}
                    className={index === 0 && styles.positionRelative}
                    tabbable={!program ? index === 0 : item.selected}
                  >
                    {index === 0 && (
                      <PlainButton
                        className={styles.videoLink}
                        onClick={handleOpenVideo}
                      >
                        <Text variant="default">{videoLink.title}</Text>
                      </PlainButton>
                    )}
                  </GettingStartedRadio>
                </Col>
              ))}
            </Row>
          </div>
        </WithSpacing>
      </ModalBody>
      <ModalFooter>
        <StepperStyledButton
          onClick={handleClick}
          text={
            program === "oldWelcomeProgram"
              ? oldWelcomeProgramCta.title
              : program === "newWelcomeProgram"
              ? newWelcomeProgramCta.title
              : somethingElseCta.title
          }
          disabled={!program}
          bold
        />
      </ModalFooter>
    </div>
  );
};

export default SelectProgramModal;
