import classNames from "classnames";
import React, { ReactNode } from "react";
import CheckboxChecked from "../../components/Inputs/Checkbox/_checkboxChecked";
import Text from "../Text/Text";
import { SanityImage } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import styles from "./GettingStartedRadio.module.scss";
import { shouldSkipMissingImageAsset } from "../Image/Image";

interface GettingStartedRadioProps {
  id: string;
  value: string;
  selected: boolean;
  onChange: (value: string) => void;
  title: string;
  subtitle: string;
  image: SanityImage;
  imageScale?: number;
  className?: string;
  tabbable: boolean;
  children?: ReactNode;
}

const GettingStartedRadio = ({
  id,
  value,
  selected,
  title,
  subtitle,
  image,
  onChange,
  imageScale,
  className,
  tabbable,
  children
}: GettingStartedRadioProps) => {
  const imageHeight = 120;
  const scaledImageHeight = imageScale ? imageScale * imageHeight : imageHeight;
  return (
    <label
      className={classNames(
        styles.root,
        className,
        selected && styles.selected
      )}
    >
      <input
        type="radio"
        name={id}
        value={value}
        checked={selected}
        onChange={() => onChange(value)}
        tabIndex={!tabbable ? -1 : undefined}
      />
      {selected && (
        <CheckboxChecked className={styles.checked} fill="#30c812" />
      )}
      <div className={styles.imageContainer} style={{ height: imageHeight }}>
        {!shouldSkipMissingImageAsset(image) && (
          <img
            alt=""
            width={
              scaledImageHeight * image.asset.metadata.dimensions.aspectRatio ??
              1
            }
            height={scaledImageHeight}
            src={
              imageUrlBuilder
                .image(image.asset)
                .height(scaledImageHeight * 2)
                .url()!
            }
          />
        )}
      </div>
      <div className={styles.text}>
        <Text variant="defaultBold">{title}</Text>
        <Text variant="default">{subtitle}</Text>
      </div>
      {children}
    </label>
  );
};

export default GettingStartedRadio;
