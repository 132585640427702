import classNames from "classnames";
import React, { ReactNode } from "react";
import styles from "./ModalBody.module.scss";

interface ModalBodyProps {
  children: ReactNode;
  className?: string;
}

const ModalBody = ({ className, children }: ModalBodyProps) => (
  <div className={classNames(styles.root, className)}>{children}</div>
);

export default ModalBody;
