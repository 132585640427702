import React from "react";
import { useString } from "../../../../i18n/GetText";
import { useLanguage } from "../../../../i18n/LanguageContext";
import {
  findContent,
  SanityLandingPageString
} from "../../../../model/landing-page";
import {
  SanityCallToAction,
  SanityYoutubeVideo
} from "../../../../model/common";
import { createLinkTo } from "../../../../utils/links";
import Button from "../../../Button/StandardButton/Button";
import Link from "../../../Link/Link";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps } from "../../../PageModal/PageModal";
import WithSpacing from "../../../Spacing/Spacing";
import Tabs from "../../../Tabs/Tabs";
import Text from "../../../Text/Text";
import styles from "./TransferCodeModal.module.scss";

interface TransferCodeModalProps extends PageModalBodyProps {
  modalId: "computer" | "tablet";
}

const TransferCodeModal = ({
  content,
  onClose,
  modalId
}: TransferCodeModalProps) => {
  const [subtitle] = findContent<SanityLandingPageString>("subtitle", content);
  const [secondaryCTA] = findContent<SanityCallToAction>(
    "secondaryCTA",
    content
  );
  const [tabTitleOne, tabTitleTwo] = findContent<SanityLandingPageString>(
    "buttonText",
    content
  );
  const language = useLanguage();
  const [videoOne, videoTwo] = findContent<SanityYoutubeVideo>(
    "videos",
    content
  );
  const closeButtonText = useString("close");
  return (
    <div className={styles.root}>
      <ModalBody>
        <WithSpacing>
          <div>
            <Text variant="default">{subtitle.value}</Text>
            <Tabs
              className={styles.tabs}
              node={{
                _key: "transfer-code-modal",
                content: [
                  {
                    title: tabTitleOne.value,
                    content: [videoOne],
                    queryParameterValue:
                      modalId === "tablet" ? "iOS" : undefined
                  },
                  {
                    title: tabTitleTwo.value,
                    content: [videoTwo],
                    queryParameterValue:
                      modalId === "tablet" ? "android" : undefined
                  }
                ],
                layout: "genericContent",
                useTabButtons: true,
                queryParameter: "device"
              }}
            />
          </div>
        </WithSpacing>
      </ModalBody>
      <ModalFooter className={styles.footer}>
        <Text variant="default">
          <Link to={createLinkTo(secondaryCTA, language)} onClick={onClose}>
            {secondaryCTA.title}
          </Link>
        </Text>
        <Button onClick={onClose} primary>
          {closeButtonText}
        </Button>
      </ModalFooter>
    </div>
  );
};

export default TransferCodeModal;
