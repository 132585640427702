import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import GettingStartedContent from "../components/GettingStartedContent/GettingStartedContent";
import { useString } from "../i18n/GetText";
import OneColumnLayout from "../layouts/OneColumnLayout/OneColumnLayout";
import PageLayoutContent from "../layouts/PageLayoutContent/PageLayoutContent";
import Providers from "../layouts/Providers/Providers";
import { CommonContext } from "../model/common";
import { SanityGettingStartedPage } from "../model/get-started";
import { GlobalMenus } from "../model/menu";
import { SiteArea } from "../utils/analytics";
import styles from "./GettingStartedPage.module.scss";

export const pageQuery = graphql`
  query GettingStartedPage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityGettingStartedPage(_id: { eq: $_id }) {
      _id
      _type
      language
      title
      slug {
        current
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      translationSponsors {
        ...TranslationSponsors
      }
      licence {
        ...Licence
      }
      pageModals {
        type
        _key
        title
        _rawContent(resolveReferences: { maxDepth: 8 })
        dismissible
        showOnPageView
      }
    }
  }
`;

const GettingStartedPage = ({
  pageContext,
  data: { menus, page },
  location
}: GettingStartedPageProps) => {
  return (
    <Providers
      language={page.language ?? "en"}
      location={location}
      menus={menus}
      strings={pageContext.strings}
      siteArea={SiteArea.GET_STARTED}
    >
      <Content pageContext={pageContext} menus={menus} page={page} />
    </Providers>
  );
};

const Content = ({
  pageContext,
  page,
  menus
}: {
  pageContext: CommonContext;
  page: SanityGettingStartedPage;
  menus: GlobalMenus;
}) => {
  // We go to a bunch of work here so we can call this inside the providers,
  // for most templates we already have a suitable title.
  const { title: h2, _rawContent, pageModals } = page;
  const title = useString("getting-started");
  return (
    <PageLayoutContent
      metadata={{
        title,
        page,
        alternates: pageContext.alternates
      }}
      menus={menus}
    >
      <OneColumnLayout
        title={title}
        visuallyHiddenTitle={`${title}: ${h2}`}
        className={styles.root}
        main={
          <GettingStartedContent
            content={_rawContent}
            pageModals={pageModals}
          />
        }
        widths={{ sm: 10, md: 10 }}
      />
    </PageLayoutContent>
  );
};

export default GettingStartedPage;

interface GettingStartedPageProps {
  data: {
    menus: GlobalMenus;
    page: SanityGettingStartedPage;
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
