import React from "react";
import GetText from "../../../../i18n/GetText";
import { findContent } from "../../../../model/landing-page";
import { SanityYoutubeVideo } from "../../../../model/common";
import Button from "../../../Button/StandardButton/Button";
import ModalBody from "../../../Modal/ModalBody/ModalBody";
import ModalFooter from "../../../Modal/ModalFooter/ModalFooter";
import { PageModalBodyProps } from "../../../PageModal/PageModal";
import Text from "../../../Text/Text";
import YoutubeVideo from "../../../YoutubeVideoBlock/YoutubeVideoBlock";

const VideoModal = ({ content, onClose }: PageModalBodyProps) => {
  const [video] = findContent<SanityYoutubeVideo>("video", content);
  return (
    <div>
      <ModalBody>
        <YoutubeVideo node={video} />
      </ModalBody>
      <ModalFooter>
        <Button primary onClick={onClose}>
          <Text variant="default">
            <GetText id="close" />
          </Text>
        </Button>
      </ModalFooter>
    </div>
  );
};

export default VideoModal;
